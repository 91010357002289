import { useState, useCallback } from "react";
import { useNavigate } from "react-router";
import { FormattedMessage, useIntl } from "react-intl";
import { NewStagingLocationDialog, AlertSnackbar } from "components";
import { useSelector, useDispatch } from "react-redux";
import { permissionSelector } from "redux/userSlice";
import { updateLocationState, removeLocation } from "redux/locationsSlice";
import { Avatar, Chip, Card, CardContent, CardHeader, CardMedia, IconButton, Tooltip, Typography, Stack } from "@mui/material";
import { EditOutlined, Palette, FormatPaint, QuestionMark } from "@mui/icons-material";
import { selectStreetArtCategoryTypesConf } from "redux/configurationSlice";

export default function LocationMeasurePointCard(props) {
    const { location } = props;
    const groupAdmin = useSelector((state) => permissionSelector(state, 'update-location'))
    const streetArtCategoryType = useSelector(selectStreetArtCategoryTypesConf).find(type => type.key === location.touristAttraction.streetArtCategoryType)
    const navigate = useNavigate();
    const locale = useIntl().locale;
    const [openFormDialog, setOpenFormDialog] = useState(false);
    const [alert, setAlert] = useState({ open: false });
    const dispatch = useDispatch();

    const handleClickOpenForm = () => {
        setOpenFormDialog(true);
    };

    const handleCloseForm = () => {
        setOpenFormDialog(false);
    };

    const handleUpdateLocationState = (type, data) => {
        dispatch(updateLocationState(data));
        setAlert({ open: true, message: { id: "SUCCESS.UPDATE" }, severity: "success" });
    }

    const renderPinIcon = useCallback(() => {
        switch (streetArtCategoryType.key) {
            case 2:
                return <Palette />;
            case 1:
                return <FormatPaint />;
            case 0:
            default:
                return <QuestionMark />;
        }
    }, [streetArtCategoryType]);

    const getColor = useCallback(() => {
        switch (streetArtCategoryType.key) {
            case 2:
                return 'culturalHeritage';
            case 1:
                return 'primary';
            case 0:
            default:
                return 'default';
        }
    }, [streetArtCategoryType]);

    const descLength = location.description && location.description[locale] && location.description[locale].length;
    const onAlertClose = () => setAlert({ ...alert, open: false });

    const onDeleteHandler = (locationData) => {
        setAlert({ open: true, message: { id: "SUCCESS.DELETE" }, severity: "success" });
        setTimeout(()=>dispatch(removeLocation(locationData)), 700);
    }

    return (
        <Card sx={{ width: '100%' }}>
            <AlertSnackbar open={alert.open} onClose={onAlertClose} severity={alert.severity} message={alert.message} />
            <CardMedia
                component="img"
                height="250"
                sx={{ cursor: 'pointer' }}
                onClick={() => navigate("/locations/details/" + location._id)}
                image={process.env.REACT_APP_API_BASE_URL + "/" + location.picture}
                alt="card-media"
            />
            <CardHeader
                title={<Typography variant={location.logo ? "h6" : "h5"}>{location.name}</Typography>}
                subheader={<Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 0.5, sm: 1 }} justifyContent="flex-start" alignItems="flex-start">
                    <Chip color={getColor()} size="small" icon={renderPinIcon()} label={<FormattedMessage id={"STREET_ART_CATEGORY." + streetArtCategoryType.value} />} />
                </Stack>}
                avatar={location.logo && <Avatar src={process.env.REACT_APP_API_BASE_URL + process.env.REACT_APP_IMG_PATH + location.logo} />}
                action={
                    [
                        groupAdmin && groupAdmin.includes(location._id) ?
                            <Tooltip key="editLocationGroupTooltip" title={<FormattedMessage id="UPDATE" />} placement="top-start" arrow>
                                <IconButton key="editLocationGroup" onClick={() => handleClickOpenForm(location._id)} ><EditOutlined /></IconButton>
                            </Tooltip> : null
                    ]
                }
            />
            <CardContent>
                <Typography variant="body2" color={descLength ? "inherit" : "text.disabled"}>
                    {descLength ? location.description[locale] : <FormattedMessage id="NO_DESCRIPTION" />}
                </Typography>
            </CardContent>
            <NewStagingLocationDialog updateLocation location={location} open={openFormDialog} onClose={handleCloseForm} pinnedPosition={location.position} onDelete={onDeleteHandler} onSubmit={handleUpdateLocationState} />
        </Card >

    );
}
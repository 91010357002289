import React, { useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { selectMeasurePoints } from 'redux/locationsSlice';
import { LocationMeasurePointCard, LocationSearchFilters } from "components";
import { Grid, Stack } from "@mui/material";

export default function LocationGroupsCardView(props) {
    const locations = useSelector(selectMeasurePoints);
    const [searchValue, setSearchValue] = useState(null);
    const [searchStreetArtCategories, setSearchStreetArtCategories] = useState([]);
    const [searchStreetArtTypes, setSearchStreetArtTypes] = useState([]);
    const [streetArtTypesMethod, setStreetArtTypesMethod] = useState("some");
    const [searchAccessibilityOptions, setSearchAccessibilityOptions] = useState([]);
    const [accessibilityMethod, setAccessibilityMethod] = useState("some");

    const filterHandler = useCallback((type, data) => {
        switch (type) {
            case 'search':
                setSearchValue(data);
                break;
            case 'attraction':
                setSearchStreetArtCategories(data);
                break;
            case 'tourism':
                setSearchStreetArtTypes(data);
                break;
            case 'streetArtTypesMethod':
                setStreetArtTypesMethod(data);
                break;
            case 'accessibility':
                setSearchAccessibilityOptions(data);
                break;
            case 'accessibilityMethod':
                setAccessibilityMethod(data);
                break;
            default:
                setSearchValue(null);
                setSearchStreetArtTypes([]);
                setStreetArtTypesMethod("some");
                setSearchAccessibilityOptions([]);
                setAccessibilityMethod("some");
                break;
        }
    }, [setSearchValue, setSearchStreetArtCategories, setSearchStreetArtTypes, setStreetArtTypesMethod, setSearchAccessibilityOptions, setAccessibilityMethod]);

    return <Stack direction="column" spacing={0.5} sx={{ width: '100%' }}>
        <LocationSearchFilters searchValue={searchValue} streetArtCategoryValues={searchStreetArtCategories} streetArtTypeValues={searchStreetArtTypes} streetArtTypesMethod={streetArtTypesMethod} accessibilityValues={searchAccessibilityOptions} accessibilityMethod={accessibilityMethod} filterHandler={filterHandler} />
        <Grid container direction="row" spacing={2}>
            {
                locations
                    .filter(location => searchValue === null ? true : location.name.includes(searchValue))
                    .filter(location => !searchStreetArtCategories.length ? true : searchStreetArtCategories.includes(location.touristAttraction.streetArtCategoryType))
                    .filter(location => !searchStreetArtTypes.length ? true
                        : location.touristAttraction.streetArtType.length ? searchStreetArtTypes.map(t => t.key)[streetArtTypesMethod](el => location.touristAttraction.streetArtType.includes(el)) : false
                    )
                    .filter(location => !searchAccessibilityOptions.length ? true
                        : location.touristAttraction.accessibilityOptions.length ? searchAccessibilityOptions.map(t => t.key)[accessibilityMethod](el => location.touristAttraction.accessibilityOptions.includes(el)) : false
                    )
                    .map((location) => {
                        return <Grid key={location.name} item xs={12} sm={12} md={6} lg={4} sx={{ display: 'flex', pl: '0 !important', pr: 2 }}>
                            <LocationMeasurePointCard location={location} />
                        </Grid>
                    })
            }
        </Grid>
    </Stack>;
}
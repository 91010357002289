import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector, useDispatch } from 'react-redux';
import { selectResourceTypeGroupsConf, selectStreetArtCategoryTypesConf, selectStreetArtTypesConf, selectAccessibilityOptionsConf } from "redux/configurationSlice";
import { updateLocationStateDetails } from 'redux/locationsSlice';
import { userSelector, permissionSelector } from 'redux/userSlice';
import { MeasurementsTrendGraph, DateRangeSelector, ReportingButtons, OWAttribution, AlertSnackbar, SideDrawer, LocationAlbumGallery, AddImagesForm } from "components";
import { Avatar, Tooltip, Fab, Chip, Card, CardContent, CardMedia, CardActions, CardHeader, Grid, Typography, useMediaQuery, Button } from "@mui/material";
import { AddPhotoAlternate, AccessibleOutlined, Palette, FormatPaint, QuestionMark, ImageOutlined, PlaceOutlined, ElderlyOutlined, DirectionsBusOutlined, DirectionsCarOutlined, DirectionsBikeOutlined, DirectionsWalkOutlined, InfoOutlined } from "@mui/icons-material";
import { LocationMapTab, LocationDetailsTabs, LatestMeasurementsTabs, /*LoginRequired, SamplingLogsSamplesCount, SamplingLogTypeDistributionPie, SamplingLogQualityDistributionPie */ } from 'components';
import API from 'api';


function PublicDetailsView(props) {
    const { locationEntity } = props;
    const streetArtCategoryTypes = useSelector(selectStreetArtCategoryTypesConf);
    const streetArtTypes = useSelector(selectStreetArtTypesConf);
    const accessibilityOptions = useSelector(selectAccessibilityOptionsConf);
    const createRights = useSelector((state) => permissionSelector(state, 'create-album'));
    const locale = useIntl().locale;
    const [mode, setMode] = useState("map");
    const [open, setOpen] = useState(false);
    const [details, setDetails] = useState({});
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [refreshData, setRefreshData] = useState(false);

    const handleOpenDialog = () => {
        setIsDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setIsDialogOpen(false);
    };


    const handleModeChange = () => {
        if (mode === "map") setMode("img");
        else setMode("map");
    }

    const renderIcon = (pointType) => {
        switch (pointType) {
            case 2:
                return <Palette />;
            case 1:
                return <FormatPaint />;
            case 0:
            default:
                return <QuestionMark />;
        }
    };

    const getColor = (streetArtCategoryType, theme) => {
        switch (streetArtCategoryType) {
            case 2:
                return theme.palette.culturalHeritage.main;
            case 1:
                return theme.palette.primary.main;
            case 0:
            default:
                return theme.palette.text.disabled;
        }
    };

    const renderAccessibilityIcons = (option) => {
        switch (option) {
            case 5:
                return <ElderlyOutlined color="success" />;
            case 4:
                return <DirectionsBusOutlined color="success" />;
            case 3:
                return <DirectionsCarOutlined color="success" />;
            case 2:
                return <DirectionsBikeOutlined color="success" />;
            case 1:
                return <DirectionsWalkOutlined color="success" />;
            case 0:
                return <AccessibleOutlined color="success" />;
            default:
                return null
        }
    }

    const toggleDrawer = () => {
        setOpen(!open);
    };

    const streetArtCategoryType = locationEntity.touristAttraction.streetArtCategoryType;
    const streetArtTypeArray = locationEntity.touristAttraction.streetArtType;
    const accessibilityOptionsArray = locationEntity.touristAttraction.accessibilityOptions;

    useEffect(() => {
        const info = locationEntity.touristAttraction.additionalInfo
        setDetails({
            title: <FormattedMessage id='ADDITIONAL_INFO' />,
            subtitle: locationEntity.name,
            display: info && info.length ? info : <Typography color="text.disabled"><FormattedMessage id='NO_DATA' /></Typography>
        });
    }, [locationEntity.name, locationEntity.touristAttraction.additionalInfo]);

    useEffect(() => {
        return () => setRefreshData(false);
    })

    return <>
        <SideDrawer open={open} state={details} toggleDrawer={toggleDrawer} />
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6} sx={{ display: 'flex' }}>
            <Card sx={{ width: '100%' }}>
                <CardHeader
                    action={
                        accessibilityOptionsArray.length ?
                            accessibilityOptionsArray.map(el => (
                                <Tooltip title={<FormattedMessage id={"ACCESSIBILITY_OPTIONS." + accessibilityOptions.find(type => type.key === el).value} />} placement="bottom" arrow key={el}>
                                    {renderAccessibilityIcons(el)}
                                </Tooltip>
                            )) : null
                    }
                    avatar={<Avatar sx={theme => ({ bgcolor: getColor(streetArtCategoryType, theme) })}>{renderIcon(streetArtCategoryType)}</Avatar>}
                    title={<Typography variant="h5">{locationEntity.name}</Typography>}
                    subheader={locationEntity.submitted !== undefined && locationEntity.submitted === false ? <Typography color="error.main"><FormattedMessage id="STAGING.PENDING.SUBMIT" /></Typography> :
                        locationEntity.addedBy
                            ? <Typography color={!locationEntity.reviewedBy ? "warning.main" : "info.main"}><FormattedMessage id={"STAGING.PENDING." + (!locationEntity.reviewedBy ? "REVIEW" : "APPROVE")} /></Typography>
                            : <FormattedMessage id={"STREET_ART_CATEGORY." + streetArtCategoryTypes.find(el => el.key === streetArtCategoryType).value} />
                    }
                />

                <CardContent>{locationEntity.description && locationEntity.description[locale] && locationEntity.description[locale].length ? locationEntity.description[locale] : <Typography color="text.disabled"><FormattedMessage id="NO_DESCRIPTION" /></Typography>}</CardContent>

                {streetArtTypeArray.length ? <CardContent>
                    {streetArtTypeArray.map(type => <Chip sx={{ mr: 1, mb: 1 }} color="primary" variant="outlined" key={"type-" + type} label={<FormattedMessage id={"STREET_ART_TYPE." + streetArtTypes.find(ttp => ttp.key === type).value} />} />)}
                </CardContent> : null}
            </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6} sx={{ display: 'flex' }}>
            <div style={{ position: 'relative' }}>
                <Tooltip title={<FormattedMessage id={"DETAILS.SWITCH_" + mode.toUpperCase()} />} arrow placement="right">
                    <Fab sx={{ position: 'absolute', top: 8, left: 8 }} size="small" onClick={handleModeChange}>
                        {mode === "map" ? <ImageOutlined /> : <PlaceOutlined />}
                    </Fab>
                </Tooltip>
            </div>
            <Card sx={{ width: '100%' }}>
                {mode === "map"
                    ? <LocationMapTab location={locationEntity} noInput />
                    : <CardMedia
                        component="img"
                        image={process.env.REACT_APP_API_BASE_URL + "/" + locationEntity.picture}
                        alt="location-picture"
                    />
                }
            </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={createRights ? 6 : 12} lg={createRights ? 6 : 12} xl={createRights ? 6 : 12}>
            <Card>
                <CardActions>
                    <Button
                        sx={{ mx: 'auto' }}
                        size="small"
                        startIcon={<InfoOutlined />}
                        onClick={toggleDrawer}
                        name='additionalInfo' key="additionalInfo"
                    >
                        <FormattedMessage id="ADDITIONAL_INFO" />
                    </Button>
                </CardActions>
            </Card>
        </Grid>
        {createRights ? <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Card>
                <CardActions>
                    <Button
                        sx={{ mx: 'auto' }}
                        size="small"
                        startIcon={<AddPhotoAlternate />}
                        onClick={handleOpenDialog}
                        name='newAlbum' key="newAlbum"
                    >
                        <FormattedMessage id="ADD_ALBUM" />
                    </Button>
                </CardActions>
            </Card>
        </Grid> : null}
        <LocationAlbumGallery location={locationEntity} refreshData={refreshData} setRefreshData={(data) => setRefreshData(data)} />
        <AddImagesForm location={locationEntity} open={isDialogOpen} onClose={handleCloseDialog} refreshData={() => setRefreshData(true)} />
    </>
}

export default function LocationDetailsView(props) {
    //TODO: more work needed
    const { location, locationId, publicView } = props;
    // const mediumScreen = useMediaQuery(theme => theme.breakpoints.down('lg'));
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down('md'));
    const resTypeGroups = useSelector(selectResourceTypeGroupsConf);
    const [locationEntity, setLocationEntity] = useState(location || undefined);
    const { token } = useSelector(userSelector);
    const [alert, setAlert] = useState({ open: false });
    const dispatch = useDispatch();

    useEffect(() => {
        if (locationId && location === undefined) API.locations.getLocationById(token, locationId).then(data => {
            dispatch(updateLocationStateDetails(data.data));
            setLocationEntity(data.data);
        }).catch(e => {
            console.error("Error fetching location, ", e);
            setAlert({ open: true, message: e.data, severity: "error" });
        })
    }, [dispatch, token, location, locationId]);


    const onAlertClose = () => setAlert({ ...alert, open: false });
    if (locationEntity === undefined) return <AlertSnackbar open={alert.open} onClose={onAlertClose} severity={alert.severity} message={alert.message} />;
    else {
        const gridItems = resTypeGroups.map((group) => {
            if (!publicView) return {
                title: "RESOURCE_TYPE.GROUP." + group.name,
                resourceTypes: group.name === "SERVICE" ?   // if SERVICE type group
                    locationEntity.serviceResourceTypes           // get locationServiceTypes
                    : locationEntity.resourceTypes.filter(res => group.resourceTypes.includes(res)), // else get otherResTypes
            };
            else if (publicView && (group.name !== "SERVICE" && group.name !== "SENSOR")) return {
                title: "RESOURCE_TYPE.GROUP." + group.name,
                resourceTypes: locationEntity.resourceTypes.filter(res => group.resourceTypes.includes(res))
            }
            else return null;
        }).filter(el => el !== null);

        return (
            <Grid container direction="column" spacing={2}>
                <Grid item container direction="row" spacing={2}>
                    {!publicView && <Grid item xs={12} sm={12} md={12} lg={8} xl={8} sx={{ display: 'flex' }}>
                        <LatestMeasurementsTabs location={locationEntity} />
                    </Grid>}
                    {
                        publicView
                            ? <PublicDetailsView locationEntity={locationEntity} />
                            : <Grid item xs={12} sm={12} md={12} lg={4} xl={4} sx={{ display: 'flex' }}>
                                <LocationDetailsTabs location={locationEntity} />
                            </Grid>
                    }
                    {!!gridItems.flatMap(item => item.resourceTypes).length && <Grid item xs={12} sm={12} md={12} lg={publicView ? 12 : 8} xl={publicView ? 12 : 8} sx={{ display: 'flex' }}>
                        <Card sx={{ width: '100%' }}>
                            <CardContent >
                                <DateRangeSelector column={smallScreen} />
                            </CardContent>
                        </Card>
                    </Grid>}
                    {!publicView && <Grid item xs={12} sm={12} md={12} lg={4} xl={4} sx={{ display: 'flex' }} >
                        <Card sx={{ width: '100%' }}>
                            <CardContent sx={{ display: 'flex', justifyContent: 'center' }}>
                                <ReportingButtons locationId={locationEntity._id} column={smallScreen} />
                            </CardContent>
                        </Card>
                    </Grid>}
                </Grid>
                <Grid item container direction="row" spacing={2}>
                    {gridItems.map(item => (
                        item.resourceTypes.length ?
                            <Grid key={item.title} item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <MeasurementsTrendGraph
                                    title={<FormattedMessage id={item.title} />}
                                    attribution={item.title.includes("SERVICE") ? <OWAttribution /> : undefined}
                                    locationId={locationEntity._id}
                                    resourceTypes={item.resourceTypes}
                                />
                            </Grid> : null
                    ))}
                </Grid>
            </Grid>
        );
    }
}
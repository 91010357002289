import { useState, useCallback } from "react";
import { FormattedMessage } from 'react-intl';
import { MapComponent, NewStagingLocationDialog, AlertSnackbar, IncidentReportDialog, MapFilters } from "components";
import { updateLocationState, selectLocationOptionsFilters } from "redux/locationsSlice";
import { useDispatch, useSelector } from "react-redux";
import { toLonLat } from 'ol/proj';
import { Fab, Box, Stack, Drawer, Toolbar, useMediaQuery } from "@mui/material";
import { AddLocationAltOutlined, CancelOutlined, FilterAltOutlined, FilterAltOffOutlined } from '@mui/icons-material';


export default function MapView(props) {
    const { publicView } = props;
    const [newLocationDialog, setNewLocationDialog] = useState(false);
    const [newReportDialog, setNewReportDialog] = useState(false);
    const [locationInput, setLocationInput] = useState(false);
    const [position, setPosition] = useState([]);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [alert, setAlert] = useState({ open: false });
    const dispatch = useDispatch();
    const filterLength = useSelector(selectLocationOptionsFilters);
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down("md"));

    const renderMap = useCallback(() => {

        const toggleInput = () => {
            setLocationInput(!locationInput);
            if (!locationInput) setAlert({ open: true, message: { id: "ADD_STAGING_LOCATION" }, severity: "info" });
            else setAlert({ ...alert, open: false });
        }

        const onClick = (e) => {
            setAlert({ ...alert, open: false })
            setLocationInput(false);
            setPosition(toLonLat(e.coordinate));
            setNewLocationDialog(true);
        };

        return <MapComponent className={locationInput ? 'pin-input' : null} onClick={locationInput ? onClick : null} >
            <Box sx={{ position: 'relative' }}>
                <Stack direction="column" spacing={1} className="map-fabs" justifyContent="flex-end" sx={{ position: 'absolute', top: 8, right: 8 }}>
                    {!publicView && <>
                        <Fab size={smallScreen ? "medium" : undefined} disabled={newLocationDialog || newReportDialog} color="info" variant="extended" onClick={toggleInput} >
                            {locationInput ?
                                <>
                                    <CancelOutlined sx={{ mr: 1 }} />
                                    <FormattedMessage id="CANCEL" />
                                </>
                                : <>
                                    <AddLocationAltOutlined sx={{ mr: 1 }} />
                                    <FormattedMessage id="ADD_LOCATION_GROUP" />
                                </>}
                        </Fab>
                    </>}
                    <Fab size={smallScreen ? "medium" : undefined} disabled={newLocationDialog || newReportDialog} color={filterLength ? "warning" : "default"} variant="extended" onClick={() => setOpenDrawer(true)}>
                        {filterLength ? <FilterAltOutlined sx={{ mr: 1 }} /> : <FilterAltOffOutlined sx={{ mr: 1 }} />}
                        <FormattedMessage id="FILTERS" /> {filterLength ? `(${filterLength})` : null}
                    </Fab>
                </Stack>
            </Box>
            <Box sx={{ position: 'relative' }}>
                <Box sx={{ position: 'absolute', bottom: 0 }}>
                </Box>
            </Box>
        </MapComponent >
    }, [alert, newLocationDialog, newReportDialog, locationInput, filterLength, smallScreen, publicView, setLocationInput]);

    const onSuccessHandler = (type, data) => {
        const alert = {
            message: { id: `SUCCESS${type ? "." + type : null}` }, severity: "success"
        }

        if (data) dispatch(updateLocationState(data));

        setAlert({ open: true, ...alert });
        setTimeout(() => setAlert({ open: false, ...alert }), 3000);
    }

    return <>
        {renderMap()}
        <Drawer open={openDrawer} onClose={() => setOpenDrawer(false)} anchor="right">
            <Toolbar />
            <MapFilters closeCB={() => setOpenDrawer(false)} />
        </Drawer>
        {!publicView && <>
            <AlertSnackbar open={alert.open} autoHide="none" severity={alert.severity} message={alert.message} />
            {!!position.length && <NewStagingLocationDialog key={position} open={newLocationDialog} onClose={() => setNewLocationDialog(false)} pinnedPosition={position} onSubmit={onSuccessHandler} />}
            <IncidentReportDialog open={newReportDialog} onClose={() => setNewReportDialog(false)} onSubmit={onSuccessHandler} />
        </>}
    </>;
}
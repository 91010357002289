import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl, FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router';
import { userSelector, permissionSelector } from 'redux/userSlice';
import { updateLocationState, removeLocation } from 'redux/locationsSlice';
import { selectStreetArtCategoryTypesConf } from 'redux/configurationSlice';
import { CustomDataGrid, AlertSnackbar, NewStagingLocationDialog, ConfirmationDialog } from 'components';
import { EmptyState } from 'layouts';
import { Tooltip, useMediaQuery, Button, IconButton, Card, CardHeader, CardContent, Typography } from '@mui/material';
import { AssignmentTurnedInOutlined, AssignmentLateOutlined, EditOutlined, CheckCircleOutlined, DeleteOutlined, DoneAll, ClearOutlined } from '@mui/icons-material';
import API from 'api';

const CustomButton = (props) => {
    const { breakpoint, onClick, disabled, sx, messageId, color, children } = props;

    if (!breakpoint) return <Button onClick={onClick} disabled={disabled} sx={sx} color={color || "primary"}>
        <FormattedMessage id={messageId} />
    </Button>
    else return <Tooltip title={<FormattedMessage id={messageId} />} arrow>
        <span>
            <IconButton onClick={onClick} disabled={disabled} sx={sx} color={color || "primary"}>
                {children}
            </IconButton>
        </span>
    </Tooltip>;
}

export default function UsersLocationsTableView(props) {
    const streetArtCategoryTypes = useSelector(selectStreetArtCategoryTypesConf);
    const viewRights = useSelector((state) => permissionSelector(state, 'view-staging-location'));
    const { token } = useSelector(userSelector);
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
    const mediumScreen = useMediaQuery(theme => theme.breakpoints.down("md"));
    const intl = useIntl();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [locations, setLocations] = useState([]);
    const [submittedFlag, setSubmittedFlag] = useState(false);
    const [newLocationDialog, setNewLocationDialog] = useState(false);
    const [dialogData, setDialogData] = useState(null);
    const [alert, setAlert] = useState({ open: false });
    const [confirmDialogData, setConfirmDialogData] = useState({ open: false });

    const onAlertClose = () => setAlert({ ...alert, open: false });

    const handleEdit = (data) => {
        setDialogData(data);
        setNewLocationDialog(true);
    };
    const handleSubmit = (id) => {
        setSubmittedFlag(true);
        API.locations.updateSubmitStagingLocation(token, id).then(res => {
            if (res.data) {
                dispatch(updateLocationState(res.data));
                setAlert({ open: true, message: { id: "SUCCESS.SUBMIT" }, severity: "success" });
            }
            setSubmittedFlag(false);
        }).catch(error => {
            console.error("Error submitting staging location:", error);
            setAlert({ open: true, message: error.data, severity: "error" });
            setSubmittedFlag(false);
        })
        setConfirmDialogData({open: false});
    };
    const handleReject = (id) => {
        setSubmittedFlag(true);
        API.locations.deleteStagingLocation(token, id).then(res => {
            if (res.data) {
                dispatch(removeLocation(res.data));
                setAlert({ open: true, message: { id: "SUCCESS.REJECT" }, severity: "success" });
            }
            setSubmittedFlag(false);
        }).catch(error => {
            console.error("Error rejecting staging location:", error);
            setAlert({ open: true, message: error.data, severity: "error" });
            setSubmittedFlag(false);
        })
        setConfirmDialogData({open: false});
    };

    useEffect(() => {
        if (viewRights || !submittedFlag) API.locations.getStagingLocationsData(token).then(({ data }) => {
            if (data) setLocations(data);
        }).catch(e => console.error("ERROR fetching staging locations", e));

        else setLocations(null);
    }, [viewRights, submittedFlag, token, alert]);

    const headers = [
        {
            field: 'reviewed',
            headerName: intl.formatMessage({ id: 'REVIEWED' }),
            ...(!smallScreen && { flex: 0 }),
            renderCell: (el) => (
                el.value
                    ? <div>
                        <Tooltip title={<span><FormattedMessage id="REVIEWED_BY" /> {el.row.reviewedBy && el.row.reviewedBy.username}</span>} arrow>
                            <span>
                                <AssignmentTurnedInOutlined color="primary" />
                            </span>
                        </Tooltip>
                    </div>
                    : <AssignmentLateOutlined color="disabled" />
            ),
            minWidth: 50
        },
        {
            field: 'submitted',
            headerName: intl.formatMessage({ id: 'SUBMITTED' }),
            ...(!smallScreen && { flex: 0 }),
            renderCell: (el) => (
                el.value
                    ? <DoneAll color="success" />
                    : <ClearOutlined color="disabled" />
            ),
            minWidth: 50
        },
        {
            field: 'name',
            headerName: intl.formatMessage({ id: 'NAME' }),
            ...(!smallScreen && { flex: 1 }),
            minWidth: 150
        },
        {
            field: 'type',
            headerName: intl.formatMessage({ id: 'STREET_ART_CATEGORY' }),
            ...(!smallScreen && { flex: 1 }),
            renderCell: ({ value }) => <FormattedMessage id={value} />,
            minWidth: 100
        },
        {
            field: 'actions',
            headerName: intl.formatMessage({ id: 'ACTIONS' }),
            type: 'actions',
            getActions: (el) => (!el.row.submitted ? [
                <CustomButton key="edit" breakpoint={mediumScreen} onClick={() => handleEdit(el.row)} disabled={submittedFlag} messageId="EDIT"><EditOutlined fontSize="small" /></CustomButton>,
                <CustomButton key="submit" breakpoint={mediumScreen} onClick={() => setConfirmDialogData({open: true, title: <FormattedMessage id={"STAGING.CONFIRM.SUBMIT"}/>, buttonTitle: <FormattedMessage id={"SUBMIT"} />, customButton: () => handleSubmit(el.row.id)})} disabled={submittedFlag} messageId="SUBMIT"><CheckCircleOutlined fontSize="small" /></CustomButton>,
                <CustomButton key="reject" breakpoint={mediumScreen} color="warning" onClick={() => setConfirmDialogData({open: true, title: <FormattedMessage id={"STAGING.CONFIRM.REJECT"}/>, buttonTitle: <FormattedMessage id={"REJECT"}/>, customButton: () => handleReject(el.row.id)})} disabled={submittedFlag} messageId="REJECT"><DeleteOutlined fontSize="small" /></CustomButton>,
            ] : [<><Typography key="info" color="text.disabled"><FormattedMessage id={"STAGING.PENDING." + (el.row.reviewedBy ? "APPROVE" : "REVIEW")} /></Typography></>]),
            ...(!smallScreen && { flex: 2 }),
            minWidth: 150
        }
    ];

    const getCellClassName = (params) => {
        if (params.field === '#' && params.value !== null) {
            return params.value;
        }
    }
    const handleData = ({ row }) => {
        navigate('/staging-locations/details/' + row.id);
    }

    const closeDialogHandler = () => {
        setNewLocationDialog(false);
        setDialogData(null);
    };

    const onSubmitHandler = (type, data) => {
        dispatch(updateLocationState(data));
        setAlert({ open: true, message: { id: "SUCCESS.UPDATE" }, severity: "success" });
        closeDialogHandler();
    };

    const onDeleteHandler = (response) => {
        dispatch(removeLocation(response.data));
        setAlert({ open: true, message: { id: "SUCCESS.DELETE" }, severity: "success" });
        closeDialogHandler();
    }

    if (locations === null) return <EmptyState message={<FormattedMessage id="SPLASH.UNAVAILABLE" />}/>;

    return <Card sx={{ width: '100%' }}>
        <AlertSnackbar open={alert.open} onClose={onAlertClose} severity={alert.severity} message={alert.message} />
        <ConfirmationDialog 
            open = {confirmDialogData.open} 
            title = {confirmDialogData.title} 
            customButtonTitle = {confirmDialogData.buttonTitle} 
            handleCancel = {() => setConfirmDialogData({open: false})}
            handleCustomButton = {confirmDialogData.customButton}
        />
        {dialogData && <NewStagingLocationDialog updateStagingLocation open={newLocationDialog} location={dialogData} onClose={closeDialogHandler} pinnedPosition={dialogData.position} onSubmit={onSubmitHandler} onDelete={onDeleteHandler} />}
        <CardHeader title={<FormattedMessage id="STAGING_LOCATIONS" />} />
        <CardContent>
            <CustomDataGrid customCellClass={getCellClassName}
                rows={locations.map(loc => ({
                    ...loc,
                    id: loc._id,
                    reviewed: Boolean(loc.reviewedBy),
                    type: "STREET_ART_CATEGORY." + streetArtCategoryTypes.find(t => t.key === loc.touristAttraction.streetArtCategoryType).value
                }))}
                columns={headers}
                handleData={handleData}
            />
        </CardContent>
    </Card>
}
import { useCallback } from 'react';
import { Routes, Route, Navigate } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectInitDone } from 'redux/appSlice';
import { selectLocale } from "utils"
import {
  AboutPage,
  // AlarmsPage,
  // AlarmsProfilePage,
  LocationsPage,
  LocationsStagingPage,
  MapPage,
  // MeasurementsPage,
  // NotesPage,
  // SamplesPage,
  // AddSamplePage
  SplashPage,
} from 'pages';
import { PageError, PageLoading } from 'components';
import { languages } from 'lang';
import { IntlProvider } from "react-intl";
import './index.css';

export default function Display() {
  // bind to Redux to check if the data for application is ready
  const initDone = useSelector(selectInitDone);
  const language = useSelector(state => state.app).lang;

  const renderApp = useCallback(() => {
    if (initDone === 'failed') return <PageError messageId="ERROR.ERROR" />;

    else if (initDone || window.location.pathname.includes('IRI') || window.location.pathname === '/') return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Navigate to="/map" />} />
          <Route path="/home" element={<Navigate to="/map" />} />

          {/* <Route path="/alarms" element={<AlarmsPage />} />
            <Route path="/alarms/details/:alarmId" element={<AlarmsPage />} />
            <Route path="/alarms/profiles" element={<AlarmsProfilePage />} />
            <Route path="/alarms/profiles/:alarmProfileId" element={<AlarmsProfilePage />} /> */}

          <Route path="/locations" element={<LocationsPage group />} />
          <Route path="/locations/details/:locationId" element={<LocationsPage details />} />
          <Route path="/staging-locations" element={<LocationsStagingPage group />} />
          <Route path="/staging-locations/details/:locationId" element={<LocationsStagingPage details />} />

          {/* <Route path="/incident-reports" element={<IncidentReportsPage group />} /> */}

          <Route path="/map" element={<MapPage />} />

          <Route path="/about" element={<AboutPage />} />

          {/* <Route path="/measurements" element={<MeasurementsPage />} />
            <Route path="/measurements/:locationId" element={<MeasurementsPage />} />
            
            <Route path="notes" element={<NotesPage />} />
            <Route path="notes/:locationId" element={<NotesPage />} />
            
            <Route path="/samples" element={<SamplesPage />} />
            <Route path="/samples/:locationId" element={<SamplesPage />} />

            <Route path="/sampleEntry" element={<AddSamplePage />} />
            <Route path="/sampleEntry/:locationId" element={<AddSamplePage />} />
            <Route exact path="/sampleEntry/history" element={<AddSamplePage history />} />
            <Route exact path="/sampleEntry/history/:locationId" element={<AddSamplePage history />} /> */}

          <Route path="/*" element={<SplashPage />} />
        </Routes>
      </BrowserRouter>
    );
    else return <PageLoading messageId="SPLASH.WAIT" />;
  }, [initDone]);


  return <IntlProvider locale={selectLocale(language, true)} messages={languages[language]}>
    {renderApp()}
  </IntlProvider>
}

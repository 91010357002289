import React, { useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { fullNameSelector, organizationAdminSelector, accountControlSelector } from "redux/userSlice";
import { keycloak } from "api/AuthKeycloak";
import {
  Menu,
  MenuItem,
  Typography,
  Button,
  ListItemIcon,
  useMediaQuery,
  Divider,
  IconButton,
} from "@mui/material";
import {
  AdminPanelSettingsOutlined,
  SupervisorAccountOutlined,
  AccountCircleOutlined,
  LogoutOutlined,
} from "@mui/icons-material";

export default function UserMenu() {
  const fullName = useSelector(fullNameSelector);
  const orgAdmin = useSelector(organizationAdminSelector);
  const accControl = useSelector(accountControlSelector);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const renderMenu = useCallback(() => {

    const handleOpenUserMenu = (event) => {
      setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
      setAnchorElUser(null);
    };

    const settings = [];
    if (accControl) settings.push({
      id: "ACCOUNT",
      onClick: () => window.open(`${process.env.REACT_APP_KEYCLOAK_BASE_URL}realms/${process.env.REACT_APP_KEYCLOAK_REALM}/account`),
      icon: <AdminPanelSettingsOutlined color="primary" fontSize="small" />,
    });
    if (orgAdmin) settings.push({
      id: "REALM",
      onClick: () => window.open(`${process.env.REACT_APP_KEYCLOAK_BASE_URL}admin/${process.env.REACT_APP_KEYCLOAK_REALM}/console/#/realms/${process.env.REACT_APP_KEYCLOAK_REALM}/users`),
      icon: <SupervisorAccountOutlined color="primary" fontSize="small" />,
    });
    settings.push({
      id: "LOG_OUT",
      onClick: () =>
        keycloak.logout({ redirectUri: process.env.REACT_APP_UI_BASE_URL }),
      icon: <LogoutOutlined color="primary" fontSize="small" />,
    });

    return <>
      {smallScreen ? (
        <IconButton color="inherit" onClick={handleOpenUserMenu}>
          <AccountCircleOutlined />
        </IconButton>
      ) : (
        <Button
          color="inherit"
          onClick={handleOpenUserMenu}
          startIcon={<AccountCircleOutlined />}
          sx={{ textTransform: 'none' }}
        >
          {fullName}
        </Button>
      )}
      <Menu
        id="menu-appbar"
        anchorEl={anchorElUser}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        {smallScreen
          ? [
            <MenuItem key="user" disabled sx={{ color: "inherit" }}>
              {fullName}
            </MenuItem>,
            <Divider key="divider" />,
          ]
          : null}
        {settings.map((setting) => (
          <MenuItem key={setting.id} onClick={setting.onClick}>
            <ListItemIcon>{setting.icon}</ListItemIcon>
            <Typography textAlign="center" fontSize="small">
              {<FormattedMessage id={setting.id} />}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </>;
  }, [anchorElUser, fullName, smallScreen, accControl, orgAdmin]);

  return (
    <>
      {renderMenu()}
    </>
  );
}
